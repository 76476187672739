
import Vue from 'vue'
import { SigmaAPIClient } from '@sigmacloud/sigma-client/dist/resources/client'
import BaseResource from '@sigmacloud/sigma-client/dist/resources'
import UsersResource from '@sigmacloud/sigma-client/dist/resources/user'
import PayrollResource from '../resources/customPayrollResource'
import ProjectResource from '../../node_modules/@sigmacloud/sigma-client/dist/resources/system/project'
import ClientResource from '../../node_modules/@sigmacloud/sigma-client/dist/resources/system/client'
import UserResource from '@sigmacloud/sigma-client/dist/resources/user'
import Autocomplete from './Autocomplete.vue'
import CustomPayrollResource from '../resources/customPayrollResource'
import PayrollReportGenerator from './PayrollReportGenerator.vue'
import RecalcValidationModal from './RecalcValidationModal.vue'

import ReportsMixins from '../mixins/ReportsMixins'
import ErrorsMixins from '../mixins/ErrorsMixins'
import UserMixin from '../mixins/UserMixin'

import jwt_decode from 'jwt-decode'

declare module 'vue/types/vue' {
    interface Vue {
        getUserName(object): string
    }
}

export default Vue.extend({
    name: 'ResourceList',
    components: {
        Autocomplete,
        PayrollReportGenerator,
        RecalcValidationModal,
    },
    mixins: [ReportsMixins, ErrorsMixins, UserMixin],
    data() {
        return {
            query: '',
            resources: [],
            payrollResources: [],
            prAttributes: [],
            payrollListResource: undefined,
            instance: undefined,
            tableLoading: undefined,
            tableFields: [
                {
                    key: 'id',
                    sortable: true,
                },
                {
                    key: 'number',
                    label: 'Invoice #',
                    sortable: true,
                },
                {
                    key: 'client_name',
                    label: 'Client',
                    sortable: true,
                },
                {
                    key: 'project_name',
                    label: 'Project',
                    sortable: true,
                },
                {
                    key: 'pay_period_end_date',
                    sortable: true,
                },
                {
                    key: 'date_due',
                    sortable: true,
                },
                {
                    key: 'check_date',
                    sortable: true,
                },
                {
                    key: 'description',
                    sortable: true,
                },
                {
                    key: 'payroll_coordinator_name',
                    label: 'Payroll Coordinator',
                    sortable: true,
                    sortByFormatted: true,
                    formatter: (value) => value && this.getUserName({ attributes: value }),
                },
                {
                    key: 'status',
                    sortable: true,
                },
                'post',
                'go_to_payroll',
                'reports',
                'is_union',
                'release',
            ],
            statusChoices: ['DRAFT', 'OPEN', 'AUDITED', { text: '   ', disabled: true }, 'POSTED', { text: '   ', disabled: true }, 'CLOSED', 'VOIDED', 'DELETED'],
            unionChoices: [''],
            loading: false,
            table: 1,
            currentPage: 1,
            rows: 0,
            perPage: 20,
            paginationKey: 1,
            autocompleteResults: undefined,
            filterProps: {
                payrollCoOrdinatorId: undefined,
                endDateFrom: undefined,
                endDateTo: undefined,
                project: undefined,
                number: undefined,
                union: {
                    union: true,
                    nonUnion: true,
                },
                status: {
                    draft: false,
                    open: false,
                    audited: false,
                    posted: false,
                    closed: false,
                    voided: false,
                    deleted: false,
                },
                checkDateFrom: undefined,
                checkDateTo: undefined,
                dueDateFrom: undefined,
                dueDateTo: undefined,
                idNumFrom: undefined,
                idNumTo: undefined,
                hold_for_payment: {
                    released: true,
                    held: true,
                },
            },
            filterString: undefined,
            reportsOptions: [],
            selectedCompanyAddressId: undefined,
            coordinatorsList: [],
            oldStatus: undefined,
            oldPayroll: undefined,
            notes: undefined,
            submitted: false,
            canReleasePayroll: false,
            showRecalcModal: false,
            isCalculated: false,
            assignmentDetailList: [],
            selectedPayroll: undefined,
            selectedPayrollData: undefined,
        }
    },
    props: {
        resourceClass: Function,
        instanceGetter: Function,
        tokenGetter: Function,
    },
    computed: {
        resourceClasses() {
            return {
                CustomPayrollResource,
                ProjectResource,
                PayrollResource,
                ClientResource,
                UserResource,
            }
        },
    },
    methods: {
        async search() {
            this.loading = true
            try {
                let ResourceClass = this.$props.resourceClass as typeof BaseResource

                if (this.$data.query) {
                    let result = await ResourceClass.search(this.$data.query)
                    this.$data.resources = result.resources
                } else {
                    let result = await ResourceClass.list()
                    this.$data.resources = result.resources
                }
                this.loading = false
            } catch (error) {
                this.$emit('message', error)
                this.loading = false
            }
        },

        goToPayroll(item) {
            let data = Object.assign({}, item)
            this.$router.push({ path: `/payroll/${data.id}` })
        },

        autocompleteGoToPayroll(item) {
            this.$router.push({ path: `/payroll/${item.id}` })
        },

        setDateFilter(name, value) {
            this.filterProps[name] = value
        },

        setProjectFilter(event) {
            this.filterProps.project = event.id
        },
        resetProjectFilter(event) {
            this.filterProps.project = undefined
        },
        setInvoiceFilter(event) {
            this.filterProps.number = event.attributes.number
        },
        setClientFilter(event) {
            this.filterProps.client = event.id
        },
        setPayrollCoOrdinatorFilter(event) {
            this.filterProps.payrollCoOrdinatorId = event.attributes.id

            setTimeout(() => {
                if (!event.attributes.first_name || !event.attributes.last_name) {
                    this.$refs['payroll-coordinator-autocomplete'].debouncedInput = event.attributes.username
                }
            })
        },

        setPayrollCoOrdinatorFilterName(event) {
            const { first_name, last_name } = event.attributes

            if (first_name && last_name) {
                this.$refs['payroll-coordinator-autocomplete'].debouncedInput = `${last_name}, ${first_name}`
            }
        },

        async getPayrollCoOrdinatorsList() {
            let users = await UsersResource.filterAll({ is_staff: true })
            this.coordinatorsList = users
        },

        getUserName(user) {
            const { first_name, last_name, username } = user.attributes
            return first_name && last_name ? `${last_name}, ${first_name}` : username
        },

        async resetFilterValues() {
            this.filterProps = {
                payrollCoOrdinatorId: undefined,
                endDateFrom: undefined,
                endDateTo: undefined,
                project: undefined,
                number: undefined,
                union: {
                    union: true,
                    nonUnion: true,
                },
                client: undefined,
                status: {
                    draft: false,
                    open: false,
                    audited: false,
                    posted: false,
                    closed: false,
                    voided: false,
                    deleted: false,
                },
                checkDateFrom: undefined,
                checkDateTo: undefined,
                dueDateFrom: undefined,
                dueDateTo: undefined,
                idNumFrom: undefined,
                idNumTo: undefined,
                hold_for_payment: {
                    released: true,
                    held: true,
                },
            }
            this.$refs['project-autocomplete'].debouncedInput = ''
            this.$refs['client-autocomplete'].debouncedInput = ''
            this.$refs['number-autocomplete'].debouncedInput = ''
            this.$refs['payroll-coordinator-autocomplete'].debouncedInput = ''
            try {
                this.loading = true
                await this.filterSearchResults()
                this.loading = false
            } catch (error) {
                this.$emit('message', error)
                this.loading = false
            }
        },

        async filterSearchResults(pageNum) {
            let statusString = ''
            let activeFilters = {}
            let statusKeys = Object.keys(this.filterProps.status)
            for (let key of statusKeys) {
                if (this.filterProps.status[key]) {
                    statusString += `${key.toUpperCase()},`
                }
            }
            statusString = statusString.substring(0, statusString.length - 1)
            if (statusString.length > 0) {
                activeFilters['status__in'] = statusString
            }
            let unionString = undefined
            if (!this.filterProps.union.union && this.filterProps.union.nonUnion) {
                // Show only non-union payrolls
                unionString = false
            } else if (this.filterProps.union.union && !this.filterProps.union.nonUnion) {
                // Show only union payrolls
                unionString = true
            }
            if (unionString !== undefined) {
                activeFilters['is_union'] = unionString
            }
            if (this.filterProps.project) {
                activeFilters['project'] = this.filterProps.project
            }
            if (this.filterProps.number) {
                activeFilters['number'] = this.filterProps.number
            }
            if (this.filterProps.client) {
                activeFilters['project__client'] = this.filterProps.client
            }
            if (this.filterProps.payrollCoOrdinatorId) {
                activeFilters['payroll_coordinator'] = this.filterProps.payrollCoOrdinatorId
            }
            if (this.filterProps.checkDateFrom) {
                activeFilters[`check_date__gte`] = this.filterProps.checkDateFrom
            }
            if (this.filterProps.checkDateTo) {
                activeFilters['check_date__lte'] = this.filterProps.checkDateTo
            }
            if (this.filterProps.dueDateFrom) {
                activeFilters['due_date__gte'] = this.filterProps.dueDateFrom
            }
            if (this.filterProps.dueDateTo) {
                activeFilters['due_date__lte'] = this.filterProps.dueDateTo
            }
            if (this.filterProps.endDateFrom) {
                activeFilters['pay_period_end_date__gte'] = this.filterProps.endDateFrom
            }
            if (this.filterProps.endDateTo) {
                activeFilters['pay_period_end_date__lte'] = this.filterProps.endDateTo
            }
            if (this.filterProps.idNumFrom) {
                activeFilters['id__gte'] = this.filterProps.idNumFrom
            }
            if (this.filterProps.idNumTo) {
                activeFilters['id__lte'] = this.filterProps.idNumTo
            }
            let released: boolean | undefined
            if (!this.filterProps.hold_for_payment.released && this.filterProps.hold_for_payment.held) {
                // Show only held payrolls
                released = false
            } else if (this.filterProps.hold_for_payment.released && !this.filterProps.hold_for_payment.held) {
                // Show only released payrolls
                released = true
            }
            if (released !== undefined) {
                activeFilters['time_released__isnull'] = !released
            }

            activeFilters['page_size'] = this.perPage
            if (pageNum && typeof pageNum === 'number') {
                activeFilters['page'] = pageNum
                this.currentPage = pageNum
            } else {
                this.currentPage = 1
            }
            this.loading = true
            try {
                let results = await CustomPayrollResource.filter(activeFilters)
                this.setPrTableData(results.resources)
                this.payrollResources = results.resources
                this.rows = results.count
            } catch (error) {
                this.$emit('message', error)
                this.loading = false
            } finally {
                this.paginationKey++
            }
        },
        async validateRecalculation(payrollData) {
            try {
                this.loading = true
                const result: any = await BaseResource.wrap(`/prs/${payrollData.id}/validate_recalc`).get()
                if (result && result.data && result.data.recalc_assignments.length) {
                    this.$refs['recalculation-modal'].show()
                    this.assignmentDetailList = result.data.recalc_assignments
                } else {
                    this.isCalculated = true
                    this.postPayroll(this.selectedPayrollData)
                }
            } catch (e) {
                // ignore
            }
        },
        callAfterRecalculation() {
            this.loading = false
            this.isCalculated = true
            this.$refs['recalculation-modal'].hide()
            this.postPayroll(this.selectedPayrollData)
        },
        async postPayroll(item) {
            this.loading = true
            item.loading = true
            let data = Object.assign({}, item)
            this.$emit('hide-all-errors')

            //Find resource
            let payrollResource = this.payrollResources.find((obj) => {
                return obj.id === data.id
            })
            this.selectedPayroll = payrollResource
            this.selectedPayrollData = data
            if (!payrollResource) {
                if (data.id) {
                    try {
                        payrollResource = await CustomPayrollResource.detail(data.id)
                    } catch (error) {
                        this.$emit('message', error)
                    }
                } else {
                    this.$emit('message', `An error occurred while attempting to save payroll ${item.id}.  Please refresh your browser and try again.`)
                    return
                }
            }

            try {
                if (data.status === 'POSTED' && !this.isCalculated) {
                    await this.validateRecalculation(data)
                    return
                }
                await payrollResource.saveWithPostedStatusHandling()
                if (data.status === 'DELETED') {
                    await this.filterSearchResults()
                } else {
                    await payrollResource.update()
                }
            } catch (error) {
                if (error.response) {
                    this.$emit('message', error.response)
                }

                if (error.messages) {
                    error.messages.forEach((message) => {
                        this.$emit('message', message)
                    })
                } else {
                    this.$emit('message', error)
                }
            } finally {
                item.loading = false
                this.isCalculated = false
                this.loading = false
            }
        },

        setStatus(item) {
            let data = Object.assign({}, item.item)
            if (!this.oldStatus) {
                this.oldStatus = data.status
                this.oldPayroll = data.id
            }

            data.status = item.value
            //set attr
            let attrObj = this.prAttributes.find((obj) => {
                return obj.id === data.id
            })
            attrObj.status = item.value

            //set res
            let prRes = this.payrollResources.find((obj) => {
                return obj.id === data.id
            })
            let prObjIndex = this.payrollResources.indexOf(prRes)

            this.payrollResources[prObjIndex].set('status', data.status)
        },

        hideNotes(elementId = null) {
            if (!this.oldPayroll) {
                return
            }
            let attrObj = this.prAttributes.find((obj) => {
                return obj.id === this.oldPayroll
            })

            attrObj.status = this.oldStatus

            //set res
            let prRes = this.payrollResources.find((obj) => {
                return obj.id === this.oldPayroll
            })
            let prObjIndex = this.payrollResources.indexOf(prRes)

            this.payrollResources[prObjIndex].set('status', this.oldStatus)

            this.oldPayroll = undefined
            this.oldStatus = null
            this.submitted = false

            if (elementId) {
                this.$root.$emit('bv::hide::popover', elementId)
            }
        },

        async saveNotes(elementId = null, data) {
            this.submitted = true
            if (this.notes) {
                try {
                    this.postNotes(data)
                } catch (error) {
                    this.$emit('message', error)
                }
            }
            await this.postPayroll(data.item)
            this.oldPayroll = undefined
            this.oldStatus = null
            this.notes = null
        },

        async postNotes(data) {
            let notesResource = BaseResource.wrap(`/prs/${data.item.id}/notes`)
            let user_id = await this.getUserId()
            await notesResource.post({
                comment: this.notes,
                payroll: data.item.id,
                user_id: user_id,
            })
        },

        async releasePayroll(item) {
            const data = Object.assign({}, item.item)
            const payrollResource = this.payrollResources.find((obj) => {
                return obj.id === data.id
            })
            const payrollReleaseResource = payrollResource.wrap(`/release`)
            const payrollResponse = await payrollReleaseResource.post()
            const prObjIndex = this.payrollResources.indexOf(payrollResource)
            this.payrollResources[prObjIndex].set('released_by_name', payrollResponse.data.released_by_name)
            this.payrollResources[prObjIndex].set('time_released', payrollResponse.data.time_released)
        },

        async filterTableData(searchTerm) {
            this.loading = true
            try {
                let results = await CustomPayrollResource.search(searchTerm, { page_size: this.perPage })
                this.payrollResouces = results.resources
                this.setPrTableData(results.resources)
                this.rows = results.count
            } catch (error) {
                this.$emit('message', error)
                this.loading = false
            }
        },

        setPrTableData(prPage) {
            this.loading = true
            let prAttrs: any = []
            for (let prResource of prPage) {
                let obj: any = {}
                if (prResource.attributes) {
                    obj = prResource.attributes
                } else {
                    obj = prResource
                }

                prAttrs.push(obj)
            }
            this.prAttributes = prAttrs
            this.table++
            this.loading = false
        },

        getCurrentUserId() {
            const instances = JSON.parse(localStorage.getItem('sigmacloud.approvals.instances') || '[]')
            const currentInstance = instances.find((instance) => {
                return instance.instanceUrl === this.instance
            })

            const decodedTokenData: { user_id: string } = jwt_decode(currentInstance.token)
            return decodedTokenData.user_id
        },

        clearAutocomplete(ref, filterName) {
            this.$refs[ref].clear()
            this.filterProps[filterName] = undefined
        },
    },
    watch: {
        filterString() {
            this.filterTableData(this.filterString)
        },
        prAttributes() {
            this.table++
        },
    },

    created() {
        let instance = this.$props.instanceGetter()
        this.instance = instance
        let token = this.$props.tokenGetter()
        BaseResource.client = new SigmaAPIClient(instance, token)
    },

    async beforeMount() {
        await this.getPayrollCoOrdinatorsList()
        for (let report of this.reports) {
            // this.reportsOptions.push(report.name)
            if (report.name !== 'Print Paychecks') {
                this.reportsOptions.push(report.name)
            }
        }
        this.canReleasePayroll = await this.userHasPermission('entry.release_payroll')
    },
})
