
import Vue from 'vue'
import UserMixin from '../mixins/UserMixin'
import CustomPayrollResource, { PayrollFeeStatusAttributes } from '../resources/customPayrollResource'

export default Vue.extend({
    name: 'RecalcValidationModal',
    components: {},
    props: {
        payroll: {
            type: Object,
            required: true,
        },
        assignmentDetailList: {
            type: Array,
        },
    },
    data() {
        return {
            hasPermissionToSelect: false,
            selectedAssignments: [],
            calculating: false,
            payrollId: undefined,
            assignmentsList: [],
        }
    },
    mixins: [UserMixin],
    beforeMount() {
        this.getPermissionToSelect()
        this.payrollId = this.$props.payroll.attributes ? this.$props.payroll.attributes.id : this.$props.payroll.id
        this.assignmentsList = this.$props.assignmentDetailList.map((ele) => {
            ele.selected = false
            return ele
        })
    },
    computed: {
        resources() {
            return {
                CustomPayrollResource,
            }
        },
    },
    methods: {
        async getPermissionToSelect() {
            const permissions = await this.getUserPermissions()
            this.hasPermissionToSelect = permissions.data.includes('entry.skip_autorecalc')
        },
        async recalculateFee() {
            if (this.calculating) {
                return
            }

            let result: PayrollFeeStatusAttributes
            let calculated: CustomPayrollResource
            if (this.hasPermissionToSelect) {
                this.selectedAssignments = this.assignmentsList.filter((ele) => ele.selected).map((assignIds) => assignIds.id.toString())
            } else {
                this.selectAssignments = this.assignmentsList.map((obj) => obj.id.toString())
            }
            try {
                this.calculating = true
                result = await this.$props.payroll.calculate({ background: true }, this.selectedAssignments, true)
                calculated = await this.$props.payroll.wait(result.fee_calc_status_id).catch((error) => {
                    this.$emit('message', error)
                })
                if (calculated) {
                    this.$emit('calculated', true)
                    this.calculating = false
                }
            } catch (apiError) {
                this.$emit('message', apiError)
                this.calculating = false
                calculated = await CustomPayrollResource.detail(this.payrollId)
            } finally {
                this.calculating = false
            }

            if (calculated) {
                try {
                    await this.payrollResource.resolveRelated({ managers: ['project', 'department'] })
                    this.latestCalculationId = result.fee_calc_status_id
                } catch (error) {
                    this.$emit('message', error)
                }
            }

            this.calculating = false
        },
    },
})
